<template>
  <section class="section">
    <form ref="form">
      <h1 class="title">Passwort vergessen</h1>
      <b-field label="Email">
        <b-input type="email" v-model="email" maxlength="200"></b-input>
      </b-field>

      <b-message
        v-if="valid === false"
        title="Fehler"
        type="is-danger"
        aria-close-label="Close message"
      >Bitte geben Sie Ihre E-Mail-Adresse an.</b-message>
      <b-message
        v-if="resetPasswordSentSuccess"
        title="Erfolgreich"
        type="is-success"
        aria-close-label="Close message"
      >Ein Link zur Wiederhestellung Ihres Passworts wurde an Ihre E-Mail-Adresse gesendet.</b-message>

      <button
        v-if="!resetPasswordSentSuccess"
        class="button is-success"
        @submit.prevent="submit()"
        @click.prevent="submit()"
      >Senden</button>
      <button class="button is-info is-pulled-right" @click="gotoLogin()">&laquo; zurück zum Login</button>
    </form>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PasswordForgotten",
  data() {
    return {
      valid: true,
      email: "",
      error: false
    };
  },
  computed: {
    ...mapGetters(["resetPasswordSentSuccess"])
  },
  methods: {
    submit() {
      if (this.validateForm()) {
        this.$store.dispatch("resetPassword", {
          email: this.email
        });
      }
    },

    gotoLogin() {
      this.$store.dispatch("gotoLogin");
    },

    validateForm() {
      if (this.email !== "") {
        this.valid = true;
        return true;
      }
      this.valid = false;
      return false;
    }
  }
};
</script>