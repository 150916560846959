<template>
  <section class="section">
    <form ref="form">
      <h1 class="title">Anmeldung</h1>
      <b-field label="Email">
        <b-input type="email" v-model="email" maxlength="200"></b-input>
      </b-field>

      <b-field label="Password">
        <b-input type="password" v-model="password" password-reveal></b-input>
      </b-field>

      <b-message
        v-if="valid === false"
        title="Fehler"
        type="is-danger"
        aria-close-label="Close message"
      >Bitte geben Sie E-Mail-Adresse und Passwort zur Anmeldung ein.</b-message>

      <b-message
        v-if="loginError === true"
        title="Fehler"
        type="is-danger"
        aria-close-label="Close message"
      >Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Login Daten.</b-message>

      <button
        class="button is-success"
        @submit.prevent="submit()"
        @click.prevent="submit()"
      >Anmelden</button>
      <a @click="passwordForgotten()" class="is-pulled-right">Passwort vergessen</a>
    </form>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Signin",
  data() {
    return {
      valid: true,
      email: "",
      password: "",
      error: false
    };
  },
  computed: {
    ...mapGetters(["loginError"])
  },
  methods: {
    submit() {
      if (this.validateForm()) {
        this.$store.dispatch("userLogin", {
          email: this.email,
          password: this.password
        });
      }
    },

    passwordForgotten() {
      this.$store.dispatch("passwordForgotten", { passwordForgotten: true });
    },

    validateForm() {
      if (this.email !== "" && this.password !== "") {
        this.valid = true;
        return true;
      }
      this.valid = false;
      return false;
    }
  }
};
</script>