<template>
  <div class="login">
    <div v-if="isAuthenticated === false" class="columns">
      <div class="column is-6-desktop is-offset-3-desktop is-8-tablet is-offset-2-tablet">
        <div class="box">
          <LoginForm v-if="!passwordForgotten" />
          <PasswordForgottenForm v-if="passwordForgotten" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginForm from "@/components/LoginForm.vue";
import PasswordForgottenForm from "@/components/PasswordForgottenForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "login",
  components: {
    LoginForm,
    PasswordForgottenForm
  },
  computed: {
    ...mapGetters(["isAuthenticated", "passwordForgotten"])
  },
  created: function() {
    this.$store.dispatch("setCurrentView", "Login");
  }
};
</script>
